import * as React from 'react';
import useContentfulAssets from '../../hooks/useContentfulAssets';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import * as Styled from './dedicatedHSEStyles';
import { SECTIONS } from '../../constants';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

let animationCounter = 0;

const DedicatedHSE = ({ dedicatedHSEData }) => {
  const safetyEnv = dedicatedHSEData?.filter((homeContainers) => {
    return (
      homeContainers?.type === SECTIONS.HOMEPAGE.DEDICATED_HSE.HOME_CONTAINER
    );
  })[0];

  const safetyLogos = dedicatedHSEData?.filter((logos) => {
    return logos?.type === SECTIONS.HOMEPAGE.DEDICATED_HSE.IMAGE_LIST;
  })[0];

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.DedicatedHSETitle>{children}</Styled.DedicatedHSETitle>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.DedicatedHSEParagraph>{children}</Styled.DedicatedHSEParagraph>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.DedicatedHSEButtonStyle href={isExternalUrlHref(node?.data?.uri)? node?.data?.uri :addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node?.data?.uri)}
        target = {
          isExternalUrl(node?.data?.uri)? '_blank' : '_self'
        }>
          {children}
        </Styled.DedicatedHSEButtonStyle>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        return asset?.node?.file?.url?.includes('image') ? (
          <Styled.DedicatedHSEImages
            data-aos="fade-up"
            data-aos-delay={`${200 * animationCounter++}`}
            data-aos-once
            src={asset?.node?.file?.url}
            alt={asset?.node?.title}
          />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <React.Fragment>
      <Styled.ClearFix>
        <Styled.HomeSplitHSE>
          <div>
            <Styled.HomeHSEText>
              <div>
                {safetyEnv?.contentDetails?.raw &&
                  documentToReactComponents(
                    JSON.parse(safetyEnv?.contentDetails?.raw),
                    optionsMainStyle
                  )}
              </div>
            </Styled.HomeHSEText>
            <Styled.HomeHSEImages>
              <div>
                {safetyLogos?.contentDetails?.raw &&
                  documentToReactComponents(
                    JSON.parse(safetyLogos?.contentDetails?.raw),
                    optionsMainStyle
                  )}
              </div>
            </Styled.HomeHSEImages>
          </div>
        </Styled.HomeSplitHSE>
      </Styled.ClearFix>
    </React.Fragment>
  );
};

export default DedicatedHSE;

import styled from 'styled-components';
export const ClearFix = styled.div`
  :after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`;
export const HomeSplitHSE = styled.div`
  display: flex;
  flex-direction: row;
  padding: 120px 180px;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  @media (max-width: 1829px) {
    padding: 100px;
  }
  @media (max-width: 1199px) {
    padding: 75px 150px;
  }
  @media (max-width: 991px) {
    padding: 75px 100px;
  }
  @media (max-width: 767px) {
    display: block;
    padding: 45px 35px 15px;
  }
`;

export const HomeHSEText = styled.div`
  float: left;
  width: 45%;
  text-align: center;
  padding-right: 50px;
  @media (max-width: 1199px) {
    float: none;
    width: 100%;
    padding-right: 0;
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
`;

export const DedicatedHSETitle = styled.h2`
  font-size: 40px;
  line-height: 45px;
  text-transform: uppercase;
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'Adelle-Bold', sans-serif;
  color: #0e4c80;
  @media (max-width: 1829px) {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 35px;
  }
  @media (max-width: 1199px) {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    margin-bottom: 20px;
    br {
      display: none;
    }
  }
`;

export const HomeHSEImages = styled.div`
  float: left;
  width: 55%;
  padding-top: 25px;
  padding-left: 50px;
  @media (max-width: 1199px) {
    float: none;
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
  @media (max-width: 991px) {
    flex-basis: 100%;
    padding: 0;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
`;
export const DedicatedHSEImages = styled.img`
  width: auto;
  height: 230px;
  margin-left: 2%;
  &:nth-child(2) {
    margin-right: 4%;
  }
  @media (max-width: 1829px) {
    height: 190px;
  }
  @media (max-width: 1399px) {
    height: 150px;
    margin: 25px 0 0 0;
  }
  @media (max-width: 1199px) {
    height: 180px;
  }
  @media (max-width: 991px) {
    height: 140px;
    margin-top: 0;
    &:nth-child(1) {
      margin-right: 10px;
    }
    &:nth-child(2) {
      margin-right: 40px;
    }
    &:nth-child(3) {
      margin-right: 0;
    }
  }
  @media (max-width: 767px) {
    display: block;
    margin: 0 auto 20px auto;
    text-align: center;
    &:nth-child(1) {
      margin-right: auto;
    }
    &:nth-child(2) {
      margin-right: auto;
    }
    &:nth-child(3) {
      margin-right: auto;
    }
  }
`;
export const DedicatedHSEButtonStyle = styled.a`
  display: inline-block;
  position: relative;
  top: 0;
  padding: 17px 35px 13px 35px;
  margin: 0 5px 5px 0;
  text-transform: uppercase;
  cursor: pointer;
`;

export const DedicatedHSEParagraph = styled.p`
  font-size: 18px;
  line-height: 28px;
  font-family: 'Figtree', arial, sans-serif;
  margin-bottom: 40px;
  color: rgba(0, 0, 0, 0.8);
  @media (max-width: 1439px) {
    font-size: 18px;
    line-height: 28px;
  }
  @media (max-width: 1199px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
  }
`;
